@import url("https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700,800");
@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@400;600&display=swap");

* {
  box-sizing: border-box;
}

body {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  font-weight: 400;
  font-family: "Plus Jakarta Sans";
  font-style: normal;
}

h1,
h2,
h3,
h4,
h5,
h6,
label,
span {
  line-height: 21px;
  font-size: 14px;
  font-weight: 400 !important;
  font-family: "Plus Jakarta Sans", sans-serif;
  color: #171717;
}

body,
html,
.App,
#root,
.auth-wrapper {
  width: 100%;
  height: 100%;
}

a.btn.btn-outline-primary {
  border-color: #00d26e !important;
  background-color: transparent;
  text-decoration: none;
  height: 40px;
  line-height: 28px;
  padding: 5px 30px;
}

.btn:hover,
a.btn:hover {
  background-color: #00d26e !important;
  border-color: #00d26e !important;
}

.width-10p {
  width: 20% !important;
}

.width-15p {
  width: 15% !important;
}

.width-20p {
  width: 20% !important;
}

.width-25p {
  width: 25% !important;
}

.width-40p {
  width: 40% !important;
}

.width-50p {
  width: 50% !important;
}

.width-70p {
  width: 70% !important;
}

.width-80p {
  width: 80% !important;
}

.width-100p {
  width: 100% !important;
}

.width-33p {
  width: 33.333% !important;
}

.width-100 {
  width: 100px !important;
}

.width-160 {
  width: 160px !important;
}

.width-200 {
  width: 200px !important;
}

.width-220 {
  width: 220px !important;
}

.width-295 {
  width: 295px !important;
}

.width-300 {
  width: 300px !important;
}

.width-400 {
  width: 400px !important;
}

.width-500 {
  width: 500px !important;
}

.padding-tb-0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.padding-lr-0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.padding-l-0 {
  padding-left: 0px !important;
}

.padding-r-0 {
  padding-right: 0px !important;
}

.padding-lr-5 {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.padding-lr-10 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.text-right {
  text-align: right !important;
}

.border-dashed-right {
  border-right: 1px dashed #d1d5db !important;
  padding-right: 20px;
}

a {
  color: #60c99f !important;
}

.cursor {
  cursor: pointer !important;
}

.left-arrow-cont {
  margin-right: 10px;
}

.form-check-input:checked {
  background-color: #00976d !important;
  border-color: #00976d !important;
}

.bullet-point {
  display: list-item;
}

.navbar-light {
  background-color: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
}

.auth-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
}

.auth-inner {
  width: 540px;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 30px;
  border-radius: 10px;
  transition: all 0.3s;
  position: relative;
}

.auth-wrapper .form-control:focus {
  border-color: #167bff;
  box-shadow: none;
}

.auth-wrapper h3 {
  text-align: center;
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
}

.admin-bg {
  background-color: #ffffff;
}

.text-red {
  color: #ff0000 !important;
}

.text-green {
  color: #00976d !important;
}

.bg-black {
  background-color: #374151;
}

.bg-green {
  background-color: #00d26e;
}

.dot {
  position: absolute;
  padding: 5px;
  border-radius: 50%;
  display: inline-block;
  left: 15px;
  top: 5px;
}

.note {
  border-radius: 4px;
  background-color: #00d26e;
  font-size: 14px;
  font-weight: 500;
  padding: auto 15px;
}

.extension-box {
  padding: 35px 15px 15px;
  background-color: #f9fafb;
  text-align: center;
}

.extension-box a .note {
  color: #171717;
}

.browser-extn-note {
  font-size: 14px;
  font-weight: 200;
  color: #171717;
  text-align: justify;
}

.main-content-container {
  margin-top: 47px !important;
}

.marketplace-container {
  padding: 10px;
  border: 1px #374151 solid;
  border-radius: 6px;
  margin: 10px;
}

img.image-circle-20 {
  width: 25px;
  height: 25px;
}

.image-circle-20 {
  border-radius: 50%;
  margin-right: 10px;
}

.sub-note {
  font-size: 16px;
  color: #4b5563;
  font-weight: 300;
}

.title-image {
  margin-right: 10px;
}

/* Simple Table */
table.simple-table {
  border: 1px #e5e7eb solid;
  border-radius: 6px;
  width: 450px;
}

table.simple-table thead {
  background-color: #f9fafb;
}

table.simple-table thead th {
  font-size: 14px;
  color: #6b7280;
  font-weight: 600;
}

table.simple-table tr {
  border-bottom: 1px solid #f3f4f6;
}

table.simple-table td,
table.simple-table th {
  padding: 10px;
}

table.simple-table td {
  color: #111827;
  font-size: 14px;
  font-weight: 400;
}

.padding-lr-10 {
  padding: 0 10px;
}

.padding-lr-15 {
  padding: 0 15px;
}

.padding-lr-20 {
  padding: 0 20px;
}

.padding-lr-30 {
  padding: 0 30px;
}

hr {
  color: #f3f4f6 !important;
  opacity: 1 !important;
}

hr.dotted {
  border-top: var(--bs-border-width) dashed;
}

.down-arrow-right {
  position: absolute;
  right: 0px;
  top: 15px;
  color: #6b7280;
}

.filter-container {
  font-size: 14px;
  position: relative;
}

.filter-container>.row>div {
  display: flex;
  justify-content: flex-end;
}

.filter-container>.row div.filter-item {
  margin-left: 10px;
  margin-right: 10px;
}

.filter-container>.row div.filter-item.last {
  margin-left: 0px;
}

.filter-container>.row div.filter-item:hover {
  background-color: #effff3;
  color: #30907d;
  border-radius: 4px;
}

.filter-container>.row div.filter-item:hover span,
.filter-container>.row div.filter-item:hover .fa {
  color: #30907d;
}

.filter-container .filter-item p {
  margin-bottom: 0px !important;
  padding: 10px;
  position: relative;
}

.filter-container .dropdownContent p {
  padding: 10px 15px;
}

.filter-container .filter-item .dropdown-menu {
  border: 0px !important;
  filter: drop-shadow(0 0 0.2rem #d1d5db);
  padding: 10px 10px 10px 10px;
}

.filter-container .filter-item .dropdown-menu.condition-filter {
  width: 644px;
}

.filter-container .filter-item .dropdown-menu.column-filter {
  width: 230px;
}

.filter-container .dropdownContent p {
  padding: 0px 5px;
}

.filter-container .padding-lr-10 span {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 14px;
  font-weight: 500 !important;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #6b7280 !important;
}

.filter-container .dropdownContent p.menu {
  padding-right: 15px;
  padding-top: 10px;
}

.filter-container .dropdownContent p.menu.bookself {
  padding-right: 15px;
}

.filter-container .dropdownContent p.menu.dashboard {
  padding-right: 5px;
  padding-top: 10px;
}

.filter-container .filter-item .dropdown-menu .condition-filter select.width-184 {
  width: 184px !important;
  padding-right: 30px !important;
}

.filter-container .filter-item .dropdown-menu .condition-filter select.width-150 {
  width: 150px !important;
  padding-right: 30px !important;
}

.filter-container .filter-item .dropdown-menu .condition-filter input.width-219,
.filter-container .filter-item .dropdown-menu .condition-filter select.width-219 {
  width: 219px !important;
}

.filter-container .filter-item .dropdown-menu .condition-filter select.width-219 {
  padding-right: 30px !important;
}

.filter-container .filter-item .dropdown-menu .condition-filter input.width-100 {
  width: 100px !important;
}

.condition-filter {
  width: auto;
}

.condition-filter tr td {
  padding: 5px;
}

.condition-filter tr td .form-control {
  height: 32px !important;
  font-family: Plus Jakarta Sans;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #4b5563 !important;
  width: 184px !important;
}

.condition-filter select {
  height: 32px;
  font-family: Plus Jakarta Sans;
  font-size: 14px !important;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #4b5563;
  padding: 2px 20px 2px 10px !important;
  width: 184px !important;
}

.filter-container .add-more-btn {
  color: #00976d !important;
  font-family: Plus Jakarta Sans !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 21px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
  text-decoration: none;
}

.filter-container .add-more-btn i {
  background-image: url("assets/images//icons/circle-add-icon.svg");
  background-repeat: no-repeat;
  background-position: center;
  padding: 8px;
  margin-right: 5px;
}

.filter-container .footer {
  text-align: right;
  padding-right: 15px;
}

.filter-container .footer button {
  height: 32px !important;
  width: 92px;
}

.filter-container button.btn.btn-box {
  width: 32px !important;
  height: 32px !important;
  border: 1px solid #d1d5db !important;
  background-color: #ffffff !important;
  color: #d1d5db !important;
  padding: 0px;
  border-radius: 4px;
}

.filter-container>.row div.filter-item:hover .fa {
  color: #d1d5db !important;
}

.filter-container button.btn.btn-default {
  margin-right: 0px;
  width: 92px;
  height: 32px;
  border-radius: 4px;
  border: 1px solid #00d26e;
  color: #00d26e;
  margin-right: 10px;
}

.search-filter-container {
  width: 100%;
  position: relative;
}

.search-filter-container input {
  border: 1px #d1d5db solid;
  border-radius: 4px;
  padding: 5px 5px 5px 35px;
  height: 40px;
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  outline: none;
}

.search-filter-container i {
  position: absolute;
  left: 12px;
  top: 10px;
  color: #9ca3af;
  font-size: 16px;
}

.filter-link-container #dropdownMenuButton1 {
  cursor: pointer;
}

.filter-link-container i img {
  padding-right: 3px;
}

.column-link-container {
  cursor: pointer;
}

.column-link-container i img {
  padding-right: 3px;
}

.export-link-container {
  cursor: pointer;
}

.export-link-container i img {
  padding-right: 3px;
}

.cus-dropdown {
  display: flex;
}

.cus-dropdown .down-arrow-right {
  position: relative;
  top: 7px;
}

.cus-dropdown .dropdown-container {
  position: absolute;
  top: 40px;
  right: 0;
  z-index: 1000;
  display: none;
  background-color: #ffffff;
  border-radius: 6px 0 6px 6px;
  padding: 10px;
  min-width: 200px;
  filter: drop-shadow(0 0 0.2rem #d1d5db);
}

.cus-dropdown:hover .dropdown-container {
  display: block;
}

.cus-dropdown .dropdown-container .form-control,
.cus-dropdown .dropdown-container .form-select,
.cus-dropdown .dropdown-container button {
  height: 33px !important;
  outline: none;
}

.cus-dropdown .dropdown-container .footer {
  padding: 10px;
  text-align: right;
}

.column-filter-table tr td {
  padding: 2px 2px;
}

.column-filter-table tr td input {
  width: 100%;
}

.set-max-height-400 {
  max-height: 400px;
  overflow: hidden;
  overflow-y: auto;
}

.custom-table-footer {
  font-size: 12px;
  color: #6b7280;
  padding: 10px;
}

.custom-table-footer select.form-select {
  background-position: right 0.1rem center;
  padding: 0rem 0.7rem 0rem 0.4rem;
  font-size: 12px;
}

.custom-table-footer nav {
  border: 0px !important;
  height: auto !important;
}

.custom-table-footer nav .pagination {
  float: right !important;
}

.custom-table-footer nav ul li a {
  font-size: 12px;
  text-align: center;
  color: #4b5563 !important;
  cursor: pointer;
}

.custom-table-footer nav ul li a.page-link {
  border-radius: 4px !important;
  margin: 5px;
  background-color: #f3f4f6;
  border: 0px;
}

.custom-table-footer nav ul li a.page-link:hover {
  background-color: #a7a7a7;
}

.custom-table-footer nav ul li a.active {
  background-color: #00976d;
  color: #ffffff !important;
}

.loading-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 120px;
  text-align: center;
  color: #027920;
  position: relative;
  margin: auto;
  height: 400px;
}

@keyframes movingLine {
  0% {
    opacity: 0;
    width: 0;
  }

  33.3%,
  66% {
    opacity: 0.8;
    width: 100%;
  }

  85% {
    width: 0;
    left: initial;
    right: 0;
    opacity: 1;
  }

  100% {
    opacity: 0;
    width: 0;
  }
}

.loading-text {
  font-size: 14px;
  line-height: 36px;
  letter-spacing: 5px;
  display: flex;
  justify-content: space-evenly;
}

.loading-text span {
  animation: moveLetters 2.4s infinite ease-in-out;
  transform: translatex(0);
  position: relative;
  display: inline-block;
  opacity: 0;
  text-shadow: 0px 2px 10px rgba(46, 74, 81, 0.3);
  color: #46986e;
}

.loading-text span.logicon {
  padding-right: 10px;
}

@for $i from 1 through 7 {
  .loading-text span:nth-child(#{$i}) {
    animation-delay: $i * 0.1s;
  }
}

@keyframes moveLetters {
  0% {
    transform: translateX(-15vw);
    opacity: 0;
  }

  33.3%,
  66% {
    transform: translateX(0);
    opacity: 1;
  }

  100% {
    transform: translateX(15vw);
    opacity: 0;
  }
}

.spinner-box {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}

/* PULSE BUBBLES */
.pulse-container {
  width: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pulse-bubble {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #ffffff;
}

.pulse-bubble-1 {
  animation: pulse 0.4s ease 0s infinite alternate;
}

.pulse-bubble-2 {
  animation: pulse 0.4s ease 0.2s infinite alternate;
}

.pulse-bubble-3 {
  animation: pulse 0.4s ease 0.4s infinite alternate;
}

@keyframes pulse {
  from {
    opacity: 1;
    transform: scale(1);
  }

  to {
    opacity: 0.25;
    transform: scale(0.75);
  }
}

/* GRID STYLING */

* {
  box-sizing: border-box;
}

.form-select,
.form-control {
  font-size: 14px !important;
}

.MuiDataGrid-cellCheckbox .Mui-checked,
.MuiDataGrid-cellCheckbox .MuiCheckbox-indeterminate,
.MuiDataGrid-columnHeaderTitleContainerContent .MuiCheckbox-root {
  color: #4a986e !important;
}

.main-con-page-title-container {
  display: flex;
}

.main-con-page-title-container .title {
  margin-right: 15px;
  padding-top: 8px;
}

.my-account .card {
  border-color: #e5e7eb;
  padding: 15px 25px !important;
}

.my-account .card h2 {
  font-size: 24px;
  color: #111827;
  font-weight: 500;
}

.my-account .avartarheader {
  margin-left: 0 !important;
  margin-right: 0 !important;
  background-color: #f9fafb;
  border-radius: 8px;
  padding: 20px 10px;
  border: 1px #e5e7eb solid;
}

.my-account .avartarheader .nameDiv h5 {
  font-size: 20px;
  color: #111827;
  font-weight: 500;
  line-height: 20px;
}

.my-account .avartarheader p {
  font-size: 14px;
  color: #6b7280;
  font-weight: 400;
}

.my-account .avartarheader .btn-outline-success {
  border-color: #00976d !important;
  color: #00976d !important;
  border-radius: 4px;
}

.my-account .profile-form label {
  color: #111827;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
}

.my-account .profile-form .form-control {
  height: 40px !important;
  border: 1px #e5e7eb solid !important;
  border-radius: 4px !important;
}

.my-account .form-switch {
  margin-right: 15px;
}

.my-account.notification h6 {
  font-size: 16px;
}

.my-account.notification p {
  font-size: 14px;
  font-weight: 400;
  color: #4b5563;
}

/* Material Pagination */
.MuiPagination-ul {
  justify-content: flex-end !important;
}

.MuiPagination-ul li button {
  background-color: #f3f4f6;
  border: 1px solid #f3f4f6;
}

.MuiPagination-ul li button.Mui-selected {
  background-color: #00976d;
  border: 1px solid #00976d;
}

.table-global-action-container {
  display: flex;
}

/* Bulk Operation */
.bulk-operation-container {
  position: relative;
  margin-right: 30px;
}

.bulk-operation-container label {
  margin-right: 10px;
  font-size: 14px;
  color: #374151;
  line-height: 40px;
}

.bulk-operation-container .dropdownContent p {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0px;
  padding: 8px 20px 8px 10px;
  color: #9ca3af;
  font-size: 14px;
  border: 1px #e5e7eb solid;
  border-radius: 4px;
  cursor: pointer;
}

.bulk-operation-container .dropdownContent p i {
  position: relative;
  top: 5px;
  left: 10px;
  color: #374151;
}

.bulk-operation-container .dropdownContent p.show {
  border: 1px #00d26e solid;
}

.bulk-operation-container .dropdownContent p i.down-arrow-right {
  display: block;
}

.bulk-operation-container .dropdownContent p.show i.down-arrow-right {
  display: none;
}

.bulk-operation-container .dropdownContent p i.up-arrow-right {
  display: none;
}

.bulk-operation-container .dropdownContent p.show i.up-arrow-right {
  display: block;
}

.bulk-operation-container .dropdownContent ul.dropdown-menu {
  padding: 5px 15px;
  border-radius: 6px;
  border: 0px;
}

.bulk-operation-container .dropdownContent ul.dropdown-menu li {
  border-bottom: 1px #e5e7eb solid;
  padding: 10px 0;
  font-size: 14px;
  color: #4b5563;
  cursor: pointer;
}

/* Custom Dropdown */
.custom-dropdown-container {
  position: relative;
  display: flex;
  margin-right: 30px;
}

.custom-dropdown-container label {
  margin-right: 10px;
  font-size: 14px;
  color: #374151;
  line-height: 40px;
}

.custom-dropdown-container .dropdownContent p {
  display: flex;
  margin-bottom: 0px;
  padding: 8px 20px 8px 10px;
  color: #9ca3af;
  font-size: 14px;
  border: 1px #e5e7eb solid;
  border-radius: 4px;
  cursor: pointer;
}

.custom-dropdown-container .dropdownContent p i {
  position: absolute;
  top: 15px;
  right: 10px;
  color: #374151;
}

.custom-dropdown-container .dropdownContent p.show {
  border: 1px #00d26e solid;
}

.custom-dropdown-container .dropdownContent p i.down-arrow-right {
  display: block;
}

.custom-dropdown-container .dropdownContent p.show i.down-arrow-right {
  display: none;
}

.custom-dropdown-container .dropdownContent p i.up-arrow-right {
  display: none;
}

.custom-dropdown-container .dropdownContent p.show i.up-arrow-right {
  display: block;
}

.custom-dropdown-container .dropdownContent ul.dropdown-menu {
  padding: 5px 15px;
  border-radius: 6px;
  border: 0px;
}

.custom-dropdown-container .dropdownContent ul.dropdown-menu li {
  border-bottom: 1px #e5e7eb solid;
  padding: 10px 0;
  font-size: 14px;
  color: #4b5563;
  cursor: pointer;
}

.btn-primary.add-rules-btn {
  border-radius: 4px !important;
  padding: 5px 12px 6px 12px !important;
  height: 32px !important;
  font-size: 14px;
  font-weight: 600;
  color: #080d1c;
  margin-left: 10px;
}

.btn-primary.add-rules-btn:hover {
  text-decoration: none;
}

.back-arrow-container a {
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;
  color: #6b7280 !important;
  position: relative;
  padding-left: 20px;
}

.back-arrow-container a i {
  position: absolute;
  left: 0px;
  top: -3px;
}

.gray-box {
  padding: 15px;
  background-color: #f3f4f6;
  min-height: 180px;
}

.gray-box .gray-box-header {
  display: flex;
}

.gray-box .gray-box-header>div {
  flex: auto;
}

.gray-box .btn-link {
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #00976d !important;
  padding: 0px;
  margin-bottom: 0.5rem;
}

.ele-move-box {
  padding: 5px;
  margin-right: 10px;
}

.flag-container {
  width: 20px;
  float: left;
  margin: auto 2px !important;
}

span.rounted-icon {
  background-color: #ff0000;
  padding: 2px 10px;
  border-radius: 50%;
}

/* Flags */
i.flag-US,
i.flag-United.States,
div.flag-United.States {
  background-image: url("./assets/flags/US.png");
  background-repeat: no-repeat;
  background-position: 100%;
  padding: 15px;
  background-size: 100%;
  margin: auto;
}

i.flag-CA,
i.flag-Canada,
div.flag-Canada {
  background-image: url("./assets/flags/CA.png");
  background-repeat: no-repeat;
  background-position: 100%;
  padding: 15px;
  background-size: 100%;
  margin: auto;
}

i.flag-GB,
i.flag-United.Kingdom,
div.flag-United.Kingdom {
  background-image: url("./assets/flags/GB.png");
  background-repeat: no-repeat;
  background-position: 100%;
  padding: 15px;
  background-size: 100%;
  margin: auto;
}

i.flag-DE,
i.flag-Germany,
div.flag-Germany {
  background-image: url("./assets/flags/DE.png");
  background-repeat: no-repeat;
  background-position: 100%;
  padding: 15px;
  background-size: 100%;
  margin: auto;
}

i.flag-IT,
i.flag-Italy,
div.flag-Italy {
  background-image: url("./assets/flags/IT.png");
  background-repeat: no-repeat;
  background-position: 100%;
  padding: 15px;
  background-size: 100%;
  margin: auto;
}

i.flag-AU,
i.flag-Australia,
div.flag-Australia {
  background-image: url("./assets/flags/AU.png");
  background-repeat: no-repeat;
  background-position: 100%;
  padding: 15px;
  background-size: 100%;
  margin: auto;
}

i.flag-MX,
i.flag-Mexico,
div.flag-Mexico {
  background-image: url("./assets/flags/MX.png");
  background-repeat: no-repeat;
  background-position: 100%;
  padding: 15px;
  background-size: 100%;
  margin: auto;
}

i.flag-BR,
i.flag-Brazil,
div.flag-Brazil {
  background-image: url("./assets/flags/BR.png");
  background-repeat: no-repeat;
  background-position: 100%;
  padding: 15px;
  background-size: 100%;
  margin: auto;
}

i.flag-ES,
i.flag-Spain,
div.flag-Spain {
  background-image: url("./assets/flags/ES.png");
  background-repeat: no-repeat;
  background-position: 100%;
  padding: 15px;
  background-size: 100%;
  margin: auto;
}

i.flag-FR,
i.flag-France,
div.flag-France {
  background-image: url("./assets/flags/FR.png");
  background-repeat: no-repeat;
  background-position: 100%;
  padding: 15px;
  background-size: 100%;
  margin: auto;
}

i.flag-NL,
i.flag-Netherlands,
div.flag-Netherlands {
  background-image: url("./assets/flags/NL.png");
  background-repeat: no-repeat;
  background-position: 100%;
  padding: 15px;
  background-size: 100%;
  margin: auto;
}

i.flag-SE,
i.flag-Sweden,
div.flag-Sweden {
  background-image: url("./assets/flags/SE.png");
  background-repeat: no-repeat;
  background-position: 100%;
  padding: 15px;
  background-size: 100%;
  margin: auto;
}

i.flag-PL,
i.flag-Poland,
div.flag-Poland {
  background-image: url("./assets/flags/PL.png");
  background-repeat: no-repeat;
  background-position: 100%;
  padding: 15px;
  background-size: 100%;
  margin: auto;
}

i.flag-EG,
i.flag-Egypt,
div.flag-Egypt {
  background-image: url("./assets/flags/EG.png");
  background-repeat: no-repeat;
  background-position: 100%;
  padding: 15px;
  background-size: 100%;
  margin: auto;
}

i.flag-TR,
i.flag-Turkey,
div.flag-Turkey {
  background-image: url("./assets/flags/TR.png");
  background-repeat: no-repeat;
  background-position: 100%;
  padding: 15px;
  background-size: 100%;
  margin: auto;
}

i.flag-AE,
i.flag-United.Arab,
div.flag-United.Arab {
  background-image: url("./assets/flags/AE.png");
  background-repeat: no-repeat;
  background-position: 100%;
  padding: 15px;
  background-size: 100%;
  margin: auto;
}

i.flag-IN,
i.flag-India,
div.flag-India {
  background-image: url("./assets/flags/IN.png");
  background-repeat: no-repeat;
  background-position: 100%;
  padding: 15px;
  background-size: 100%;
  margin: auto;
}

i.flag-SG,
i.flag-Singapore,
div.flag-Singapore {
  background-image: url("./assets/flags/SG.png");
  background-repeat: no-repeat;
  background-position: 100%;
  padding: 15px;
  background-size: 100%;
  margin: auto;
}

i.flag-JP,
i.flag-Japan,
div.flag-Japan {
  background-image: url("./assets/flags/JP.jpg");
  background-repeat: no-repeat;
  background-position: 100%;
  padding: 15px;
  background-size: 100%;
  margin: auto;
}

span.flag-US,
span.flag-United.States {
  background-image: url("./assets/flags/US.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 150%;
}

span.flag-CA,
span.flag-Canada {
  background-image: url("./assets/flags/CA.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 150%;
}

span.flag-GB,
span.flag-United.Kingdom {
  background-image: url("./assets/flags/GB.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 150%;
}
span.flag-UK,
span.flag-United.Kingdom {
  background-image: url("./assets/flags/GB.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 200%;
}
span.flag-DE,
span.flag-Germany {
  background-image: url("./assets/flags/DE.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 150%;
}

span.flag-IT,
span.flag-Italy {
  background-image: url("./assets/flags/IT.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 150%;
}

span.flag-AU,
span.flag-Australia {
  background-image: url("./assets/flags/AU.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 150%;
}

span.flag-MX,
span.flag-Mexico {
  background-image: url("./assets/flags/MX.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 150%;
}

span.flag-BR,
span.flag-Brazil {
  background-image: url("./assets/flags/BR.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 150%;
}

span.flag-ES,
span.flag-Spain {
  background-image: url("./assets/flags/ES.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 150%;
}

span.flag-FR,
span.flag-France {
  background-image: url("./assets/flags/FR.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 150%;
}

span.flag-NL,
span.flag-Netherlands {
  background-image: url("./assets/flags/NL.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 150%;
}

span.flag-SE,
span.flag-Sweden {
  background-image: url("./assets/flags/SE.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 150%;
}

span.flag-PL,
span.flag-Poland {
  background-image: url("./assets/flags/PL.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 150%;
}

span.flag-EG,
span.flag-Egypt {
  background-image: url("./assets/flags/EG.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 150%;
}

span.flag-TR,
span.flag-Turkey {
  background-image: url("./assets/flags/TR.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 150%;
}

span.flag-AE,
span.flag-United.Arab {
  background-image: url("./assets/flags/AE.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 150%;
}

span.flag-IN,
span.flag-India {
  background-image: url("./assets/flags/IN.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 150%;
}

span.flag-SG,
span.flag-Singapore {
  background-image: url("./assets/flags/SG.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 150%;
}

span.flag-JP,
span.flag-Japan {
  background-image: url("./assets/flags/JP.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 150%;
}

.input-editable-container {
  position: relative;
}

.input-inside-table-colum {
  border-radius: 4px;
  border: 1px solid #e5e7eb;
  padding: 4px;
  width: 50px;
  text-align: right;
  padding-right: 17px;
}

.input-editable-container span {
  position: absolute;
  right: 5px;
  top: 5px;
}

.css-1nmdiq5-menu input[type="checkbox"] {
  margin-right: 10px;
}

.close-modal {
  font-size: 25px;
  position: absolute;
  right: 25px;
  top: 25px;
  cursor: pointer;
}

.input-btn-close {
  cursor: pointer;
}

input[type="text"]:focus,
select:focus,
.form-select:focus,
.form-control:focus,
input:focus,
.rs-picker-has-value.rs-picker-cleanable .rs-picker-toggle.rs-btn {
  outline: 0 !important;
  box-shadow: none !important;
}

span.gray-text-m,
select .gray-text-m {
  font-family: Plus Jakarta Sans !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 21px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
  color: #9ca3af !important;
}

.css-ltf0zy-MuiDataGrid-iconButtonContainer,
.css-1lymaxv-MuiDataGrid-root .MuiDataGrid-menuIcon,
.css-1lymaxv-MuiDataGrid-root .MuiDataGrid-columnSeparator--sideRight {
  position: absolute;
  right: 0px;
}

.css-ltf0zy-MuiDataGrid-iconButtonContainer {
  right: 20px;
  background-color: #ffffff;
  height: 30px;
  margin-right: 2px;
  /*padding: 2px;*/
  border-radius: 4px 0px 0px 4px;
}

.css-1lymaxv-MuiDataGrid-root .MuiDataGrid-menuIcon {
  top: 13px;
  right: 7px;
  background-color: #ffffff;
  height: 30px;
  border-radius: 0 4px 4px 0;
  padding: 2px;
}

.css-i4bv87-MuiSvgIcon-root {
  font-size: 1rem !important;
  width: 1.3em !important;
  height: 1.3em !important;
}

.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root {
  color: #d1d5db !important;
}

.MuiDataGrid-cell .form-switch .form-check-input {
  height: 20px !important;
  width: 32px !important;
}

.modal-width-540 .modal-dialog .modal-content {
  width: 540px;
}

.modal-width-540 .modal-dialog .modal-content .addTagInputContainer {
  padding: 20px;
}

.modal-width-540 .modal-dialog .modal-content .addTagInputContainer .addTagModelContainers input {
  font-family: Plus Jakarta Sans !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
  color: #9ca3af !important;
}

.modal-width-540 .modal-dialog .modal-content .addTagInputContainer .addTagModelContainers input::placeholder {
  font-family: Plus Jakarta Sans !important;
  font-size: 16px !important;
  font-weight: 400 !important;
}

.inbetween-inputfiles {
  display: flex;
  margin-top: 0px;
}

.alert-container.alert .btn-close {
  position: absolute;
  right: 15px;
  top: 15px;
}

.custom-fb-icon {
  margin: 10px 0px;
}

input::-ms-reveal {
  display: none !important;
}