footer {
  bottom: 0px;
  left: 0;
  width: 100%;
  text-align: center;
  background-color: #ffffff !important;
  padding: 0 35px;
  margin-bottom: 30px;
  margin-top: 30px;
  font-family: Plus Jakarta Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  color: #6B7280;
}
footer .left{
  text-align: left;
}
footer .right{
  text-align: right;
}
footer ul{
  list-style: none;
}
footer ul li{
  display: inline-block;
  padding-left: 40px;
}