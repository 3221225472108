/* CamapaignManagerBulkOperation.module.css */
/* Scoped styles for CamapaignManagerBulkOperation component */

/* .CamapaignManagerBulkOperation
{
}

    .CamapaignManagerBulkOperation .button-container
    {
        margin: 10px;
    }


    .CamapaignManagerBulkOperation .modal-content
    {
        width: 420px !important;
    } */

.addTagModelContainers input
{
    color: #111827 !important;
}

    .addTagModelContainers input::placeholder
    {
        color: #111827 !important;
        font-size: 14px;
    }
