.top-seller-widget img {
  border-radius: 4px;
  height: 155px;
  width: inherit;
}
p + p {
  margin-top: 0 !important;
}
.bestSellerLoading{
height: 200px !important;
}
.bestSellerLoading .loading-text{
font-size: 10px !important;
}
.bestSellerLoading .loading-text .logicon img{
width: 20px !important;
}
.bestproduct-column-titles{
  color: #111827;
  padding-top: 8px;
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  display: -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
}