/* WorldMapChart.module.css */
/* Scoped styles for WorldMapChart component */
.WorldMapChart
{
}

    .WorldMapChart .marker-container
    {
        background-color: #f0f0f0;
        border: 1px solid #d3d3d3;
        border-radius: 20px;
        padding: 5px;
        display: inline-flex;
        align-items: center;
    }

        .WorldMapChart .marker-container .marker-icon
        {
            border-radius: 50%;
            margin-right: 5px;
            width: 20px;
            height: 20px;
        }

        .WorldMapChart .marker-container .metric-value
        {
            padding-left: 2px;
            padding-right: 2px;
            font-size: 12px;
        }

    .WorldMapChart .metric-header
    {
        font-weight: 500;
        font-size: 14px;
        display: inline-flex;
    }

        .WorldMapChart .metric-header .title
        {
            color: rgb(33, 37, 41) !important;
            font-size: 14px;
            font-family: Plus Jakarta Sans, sans-serif;
            font-weight: 600 !important;
            line-height: 21px;
            word-wrap: break-word;
            margin-right:2px;
        }

        .WorldMapChart .metric-header .subtitle
        {
            color: #9CA3AF;
            font-size: 14px;
            font-family: Plus Jakarta Sans, sans-serif;
            font-weight: 500;
            line-height: 21px;
            word-wrap: break-word;
        }

        .WorldMapChart .metric-header select
        {
            font-weight: 500;
            font-size: 14px;
            color: rgb(33, 37, 41) !important;
        }

    .WorldMapChart .map-container
    {
        height: 295px;
        width: 100%;
        background-color: #fff;
        text-decoration: none;
        position: absolute;
        width: 100%;
        z-index: 1;
    }

        .WorldMapChart .map-container .popup-container
        {
            text-align: center;
            padding: 10px;
        }
