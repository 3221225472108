.fillterIcon {
  margin-bottom: 7px !important;
}

.filterText {
  margin-top: 8px;
}

.filter-menu {
  width: 30rem !important;
  padding: 10px !important;
  box-shadow: rgba(0, 0, 0, 0.05);
  border-radius: 8px;
}

.filter-menu .row {
  margin-top: 8px;
}

.filter-span {
  font-weight: 600 !important;
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #6b7280;
}

.distribution-text {
  font-weight: 400 !important;
  color: #9ca3af !important;
  font-size: 14px !important;
}

.custom-option {
  color: black; /* Default color for the text */
}

.distribution-text {
  color: red !important; /* Color for the [Distribution] part */
}

.modelText {
  font-size: 16px;
  font-weight: 600;
}

.filter-menu[data-bs-popper] {
  left: 10px !important;
}

.filter-menu .form-control {
  border: 1px solid #e6e8eb !important;
  height: 32px !important;
}

.filter-menu label {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 4px;
}

.adTypeButtons {
  display: flex;
  justify-content: space-between;
}

.adTypeButtons button {
  width: 70px;
  height: 32px;
  padding: 6px, 19px, 5px, 19px;
  border-radius: 4px;
  border: 1px;
  border: 1px solid #d1d5db;
  background-color: #fff;
}

.filterModelCancelBtn {
  width: 92px;
  height: 32px;
  padding: 6px, 22px, 5px, 22px;
  border-radius: 4px;
  border: 1px solid #00d26e;
  color: #00d26e;
  background-color: #fff;
}

.filterModelApplyFilterBtn {
  width: 108px;
  height: 32px;
  padding: 6px, 15px, 5px, 16px;
  border-radius: 4px;
  background-color: #00d26e;
  margin-left: 10px;
}

.filterModelBottomButtoms {
  text-align: end;
}

.filterBtnClick.selected {
  width: 70px;
  height: 32px;
  /* padding: 6px 9px 5px 10px; */
  border-radius: 4px;
  border: 1px;
  color: #00d26e;
  border: 1px solid #00d26e;
  background-color: #effff3;
}

.dashboard-container .widget-container {
  border: 1px #e5e7eb solid;
  border-radius: 6px;
  padding: 10px 0 0;
  margin-bottom: 30px;
  position: relative;
}
.sticky-container {
  position: sticky;
  z-index: 1;
  position: -webkit-sticky;
  top: 137px;
  background-color: white;
}
.dashboard-container .widget-container.height-220 {
  min-height: 220px;
}

.dashboard-container .widget-container.height-110 {
  min-height: 110px;
}

.dashboard-container .widget-container.height-350px {
  min-height: 350px;
}

.dashboard-container .widget-container.height-410px {
  min-height: 410px;
}

.dashboard-container .widget-container .widget-header {
  padding: 0px 0 0px 10px;
}

.semicircle-container {
  text-align: center;
}

.semicircle-container .semicircle-percent-value {
  width: 100%;
  bottom: -10px !important;
  left: 0 !important;
  color: #111827 !important;
  font-family: Plus Jakarta Sans;
  font-size: 28px;
  font-weight: 700 !important;
  line-height: 34px;
  letter-spacing: 0em;
  text-align: center;
}

.semi-circle-widget p.widget-p-sub-text {
  margin-bottom: 0px !important;
}

.semi-circle-widget .widget-p-sub-text span {
  padding-top: 15px;
  text-align: center;
  color: #6b7280;
  font-size: 14px;
  font-family: Plus Jakarta Sans;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
}

.semi-circle-widget .widget-footer {
  bottom: 15px;
}

.widget-select-container {
  padding-right: 0px !important;
}

.widget-select-container.dot-con {
  padding-left: 30px;
  position: relative;
}

.widget-container .widget-header .widget-select {
  border: none;
  padding: 0px 20px 0px 0px !important;
  border-radius: 0.2rem !important;
  height: 24px !important;
  margin: 0px 5px 0px 0px !important;
  padding: 0;
  font-weight: 600;
  line-height: 21px;
  background-position: right 0.25rem center;
  padding-right: 23px;
  font-size: 14px;
  flex: 0 0 auto !important;
  width: 70px;
  color: #9ca3af;
}

.form-select.widget-select:focus {
  border-color: #00d26e !important;
}

.widget-container .widget-header .pull-right {
  float: right;
}

.more-action-icon {
  text-align: right;
  margin-right: 10px;
  width: "8px";
  display: none;
}

.widget-p-sub-text {
  padding-top: 15px;
  text-align: center;
  color: #6b7280;
  font-weight: 300;
  font-size: 14px;
}

.widget-status-container {
  width: 75px;
  height: 24px;
  padding-left: 5px;
  margin: auto;
  position: relative;
  font-size: 14px;
  font-weight: 400;
  padding-left: 25px;
  border-radius: 4px;
}

.widget-status-container.up {
  background-color: #e1fce7;
  color: #00865d;
  background-image: url("../../assets/images/icons/up-icon.svg");
  background-position: left 5px center;
  background-repeat: no-repeat;
}

.widget-status-container.down {
  background-color: #fef6f6;
  color: #d4111e;
  background-image: url("../../assets/images/icons/down-icon.svg");
  background-position: left 5px center;
  background-repeat: no-repeat;
}

.apexcharts-canvas {
  margin: auto;
}

.widget-footer {
  position: absolute;
  bottom: 5px;
  width: 100%;
}

.widget-footer-title {
  padding-left: 10px;
  font-size: 28px;
  font-weight: 700;
  line-height: 33.6px;
  color: #111827;
}

.widget-footer .widget-status-container {
  margin-top: 10px;
  font-weight: 500;
  size: 14px;
  line-height: 21px;
}

.top-seller-widget {
  width: 100%;
  height: 175px;
  border-radius: 6px;
  border: 1px #e5e7eb solid;
  margin-bottom: 30px;
  padding: 10px;
}

.top-seller-title {
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #111827;
  height: 30px;
}

.top-seller-title .dropdown {
  padding-left: 10px;
}

.top-seller-title .dropdown .info.icon {
  position: absolute;
  left: 155px;
  top: 2px;
}

.top-seller-widget img {
  border-radius: 4px;
}

.top-seller-widget .tag-info {
  background-color: #fdda3f;
  font-size: 12px;
  font-weight: 500;
  color: #111827;
  border-radius: 3px;
  padding: 0 3px;
  line-height: 15.12px;
}

.top-seller-widget .top-seller-cont-title {
  font-size: 14px;
  font-weight: 600;
  color: #111827;
  margin-top: 10px;
  margin-bottom: 10px;
  line-height: 21px;
}

.top-seller-widget p {
  font-size: 10px;
  font-weight: 400;
  color: #111827;
  margin-bottom: 0px;
  line-height: 12.6px;
}

.dashboard-container .nav-tabs .nav-item button {
  height: 50px !important;
  font-family: Plus Jakarta Sans !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #6b7280;
}

.dashboard-container .nav-tabs .nav-item.show .nav-link,
.dashboard-container .nav-tabs .nav-item .nav-link:focus,
.dashboard-container .nav-tabs .nav-item .nav-link:hover,
.dashboard-container .nav-tabs .nav-item .nav-link.active {
  height: 50px;
  color: #00976d !important;
  border-bottom: 1px #00976d solid !important;
  font-weight: 600 !important;
}

.tabsContainer .nav {
  margin-bottom: 0 !important;
  border: 1px solid #e5e7eb;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.tabsContainer-box {
  border: 1px solid #e5e7eb;
  border-radius: 6px;
  height: 520px;
}
/* .tabsContainer {
  border: 1px solid #e5e7eb;
  padding: 0;
  margin: 0;
} */
.dashboard-container .nav-tabs .nav-item {
  height: 50px;
}

.dashboard-container .main-cont-header .dashoard-nav a {
  color: #00976d !important;
}

.spinner-border {
  color: #00976d;
  --bs-spinner-width: 15px !important;
  --bs-spinner-height: 15px !important;
  margin-bottom: 4px;
  margin-left: 10px;
  --bs-spinner-border-width: 0.1em !important;
}

@media only screen and (max-width: 1140px) {
  .top-seller-widget {
    height: 185px !important;
  }

  .top-seller-widget img {
    height: 155px !important;
    width: auto !important;
  }
}

.dashboard-container .rs-picker-default .rs-picker-toggle {
  border: none !important;
}

.dashboard-container .input-group-text {
  border: 1px solid #bcbfc2 !important;
}

.dashboard-container .input-group-text .rs-stack-item input:focus {
  border: none !important;
}

.filterTopContainer .form-select {
  padding: 0 !important;
}

.dashboard-container .filterTopContainer label {
  padding: 0px 7px 8px 12px !important;
}

.noData {
  height: 20px !important;
  font-size: 14px !important;
  font-family: Plus Jakarta Sans !important;
}

.main-cont-header .global-dashboard-filter {
  position: relative;
  width: 80px;
}

.main-cont-header .input-group.calender {
  width: 280px !important;
}

.main-cont-header .global-dashboard-filter .dropdown-menu {
  padding: 15px;
  width: 504px;
  border-radius: 8px;
  border: 0px;
  box-shadow: 1px 1px 15px 0px #cccccc;
}

.main-cont-header .global-dashboard-filter p {
  margin-bottom: 0px;
  margin-top: 10px;
}

.adTypeButtons .filterBtnClick {
  font-size: 14px;
}

.login-logo {
  text-align: center;
  padding-bottom: 10px;
}

.login-logo img {
  max-width: 220px;
}

.sync-data-daily {
  border: 1px solid #b58900;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(255, 253, 235);
  padding: 5px;
  border-radius: 5px;
  margin-top: 20px;
}

.sync-button {
  background-color: #b58900;
  color: white;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 5px;
  margin-left: auto;
  font-weight: 600px;
  size: 14px;
  line-height: 21px;
  align-items: center;
}

.sync-button:disabled {
  background-color: #b58900;
  cursor: not-allowed;
}

.close-button {
  cursor: pointer;
  font-weight: bold;
  color: #b58900;
  width: 20px;
  height: 20px;
  margin-left: 20px;
}

.syncmsg {
  color: #b58900;
  margin-top: 10px;
  font-weight: 600px;
  size: 16px;
  line-height: 24px;
}

.sync-data-container {
  width: 98%;
  margin: 0 auto;
  margin-top: -70px;
  max-width: 100%;
  padding: 15px;
}

.addpopup {
  position: absolute;
  border: 1px solid whitesmoke;
  border-radius: 5px;
  background-color: white;
  padding: 20px;
  z-index: 2;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.input-control {
  width: 440px;
  border: 1px solid #e8e8e8;
  border-radius: 0.3rem;
  margin: 10px 10px;
  padding: 0px 40px;
}

.icon-color {
  color: #e8e8e8 !important;
}

.fa-search-title {
  position: absolute;
  top: 20px;
  left: 20px;
}

.filter-search {
  z-index: 4;
}

.checkbox-adjust {
  margin: 0px 12px;
}

.filter-checkbox {
  accent-color: #00976d;
}

.list-title {
  z-index: 4;
  position: absolute;
  background-color: white;
  border: 1px solid #e8e8e8;
  border-radius: 0.2rem;
  left: 0;
  top: 45px;
  width: 468px;
  height: 400px;
  white-space: nowrap;
  overflow: scroll;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 6px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.titleList-li {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding: 0;
  list-style: none;
}

.Title-title {
  position: relative;
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  height: 40px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  appearance: none;
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.page-header .global-dashboard-filter .Title-title {
  cursor: pointer;
  margin-bottom: 10px;
  height: 32px;
  padding: 2px 12px;
  border-radius: 4px;
}

.page-header .global-dashboard-filter .Title-title span {
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #374151;
}

.page-header .global-dashboard-filter .Title-title i {
  position: absolute;
  right: 10px;
  top: 8px;
  color: #374151;
  font-size: 12px;
}

.page-header .global-dashboard-filter .Title-title .list-title {
  border: 0px;
  box-shadow: 1px 1px 10px 0px #cccccc;
  padding: 5px;
}

.page-header
  .global-dashboard-filter
  .Title-title
  .list-title
  .filter-search
  input {
  height: 32px !important;
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #9ca3af;
}

.page-header
  .global-dashboard-filter
  .Title-title
  .list-title
  .filter-search
  .fa-search-title {
  left: 50px;
  top: 10px;
}

.page-header
  .global-dashboard-filter
  .Title-title
  .list-title
  .filter-search
  .fa-search-title
  i {
  font-size: 16px;
  color: #9ca3af !important;
}

.div-container-funnel {
  margin: 0;
  padding: 0;
  text-align: center;
}

.div-container-funnel .div1:nth-child(1) {
  background-color: #cafcd0;
  color: #000;
  width: 100%;
}

.div-container-funnel .div1:nth-child(3) {
  background-color: #38e381;
  color: #000;
  margin-left: 50px;
  margin-right: 50px;
}

.div-container-funnel .div1:nth-child(2) {
  background-color: #38e381;
  color: #000;
  margin-left: 30px;
  margin-right: 30px;
  padding: 0px 0px 0px 0px;
}

.div-container-funnel .div1:nth-child(4) {
  background-color: #38e381;
  color: #000;
  margin-left: 70px;
  margin-right: 70px;
  padding: 0px 0px 0px 0px;
}

.div-container-funnel .div1:nth-child(5) {
  background-color: #00b470;
  color: #000;
  margin-left: 80px;
  margin-right: 80px;
}

.div-container-funnel .div1:nth-child(odd) {
  border-radius: 30px;
  text-align: center;
  padding: 18px 20px;
}

.div-container-funnel .div1:nth-child(even) {
  background-color: #f3f4f6;
  border-radius: 32px;
  text-align: center;
  color: #9ca3af;
  padding: 0px;
}

.div2 {
  height: 100px;
  width: 300px;
  background-color: black;
  border-radius: 50%;
  text-align: center;
  margin: 0px;
  padding-top: 0px;
  color: white;
}

.rs-picker-toolbar-ranges {
  flex-wrap: nowrap !important;
}

.custom-option {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

.highlight-text {
  color: #9ca3af;
}

.Datafilert .Minus {
  background-color: #cafcd0 !important;
  border: none;
}

.Datafilert .Plus {
  background-color: #00d26e !important;
  border: none;
}

.Datafilert .Greater {
  background-color: #00976d !important;
  border: none;
}

.Datafilert Button {
  margin: 0.5px;
  width: 60px;
  height: 32px !important;
}

.Datafilert {
  padding: 10px;
}

.lastspan {
  font-weight: 600;
}

.global-dashboard-filter .modelText {
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #111827;
}

.global-dashboard-filter .filter-span {
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  font-weight: 600 !important;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #111827;
}

.global-dashboard-filter .dropdown-menu .filter-span {
  margin-bottom: 10px;
}

.global-dashboard-filter h6.filter-span {
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-weight: 600 !important;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #111827;
  margin-bottom: 15px;
}

.widget-select-container .input-group > div > span {
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  font-weight: 500 !important;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #111827;
}

.dashboard-page .tabsContainer .css-t89xny-MuiDataGrid-columnHeaderTitle {
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  font-weight: 600 !important;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #6b7280;
}

.custom-table-footer .table-footer-left span {
  font-family: Plus Jakarta Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: #4b5563;
  padding-right: 10px;
}

.custom-table-footer .table-footer-left label select {
  font-family: Plus Jakarta Sans;
  font-size: 12px !important;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: #4b5563;
  height: 24px;
  border-radius: 4px;
}

.apexcharts-toolbar {
  z-index: -1 !important;
}

.horizontalBarChart .spinner-border {
  color: #00976d;
  --bs-spinner-width: 2rem !important;
  --bs-spinner-height: 2rem !important;
}

.rs-picker-daterange-header {
  margin-top: 26px !important;
}

.rs-picker-daterange {
  margin-right: 26px !important;
}

.custom-dropdown.clicked {
  background-color: var(--NewGreen-600, #00b470);
}

.dropdown-options {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  z-index: 1;
  max-height: 184px;
  overflow-y: auto;
}

.date-range-container {
  position: absolute;
  top: 10px;
  left: 60px;
  visibility: hidden;
  text-align: center;
}

.date-range-container.visible {
  visibility: visible;
}

.dropdown-header {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
}

.dropdown-item {
  padding: 5px;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #eaffec !important;
  color: #00b470 !important;
}

.dropdown-item.selected {
  background-color: #eaffec;
  color: #ffffff;
}

.dashboard-layout .page-header .input-group-text {
  width: 200px;
  display: flex;
  height: 40px;
  padding: 9px 12px 10px 12px;
  justify-content: space-evenly;
}

.dashboard-layout .page-header .input-group.calender .input-group-text {
  padding-right: 15px !important;
}

.custom-dropdown-header {
  font-weight: 600;
  text-align: center !important;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  width: 100%;
  position: relative;
  height: 24px;
}
/* .rs-picker-default .rs-picker-toggle.rs-btn {
  display: none;
}
.rs-picker-menu .rs-picker-toolbar-ranges {
  display: none;
} */
/* .rs-anim-fade.rs-anim-in {
  margin-left: -175px;
} */

.bright-icon {
  filter: invert(71%) sepia(75%) saturate(240%) hue-rotate(112deg)
    brightness(90%) contrast(85%);
}

.normal-icon {
  filter: none;
}
.dashboard-container .input-group.clicked .custom-dropdown .input-group-text {
  border: 1px solid #00d26e !important;
}
.custom-dropdown-header {
  display: flex;
  align-items: center;
  cursor: "pointer";
}
.custom-dropdown-header .date-left-icon {
  position: absolute;
  left: 0;
}
.custom-dropdown-header .date-text-con {
  position: absolute;
  left: 15px;
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  font-weight: 600 !important;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}
.dashboard-container
  .input-group.clicked
  .custom-dropdown-header
  .date-text-con {
  color: #00976d !important;
}
.custom-dropdown-header .arrow {
  position: absolute;
  right: 0;
}

.dashboard-calendar.dropdown-options {
  border: 0px;
  box-shadow: 1px 1px 15px 0px #cccccc;
  padding: 0px;
}
.dashboard-calendar.dropdown-options .dropdown-item {
  padding: 10px 15px !important;
  border-bottom: 1px #f3f4f6 solid;
  cursor: pointer;
}
.rs-picker-menu {
  width: 500px !important;
  z-index: 100 !important;
}
.rs-picker-daterange-menu .rs-picker-daterange-calendar-single .rs-calendar {
  height: 400px !important;
}
.rs-picker-daterange-header {
  width: 500px !important;
  padding: 20px 20px 10px 20px !important;
  font-family: Plus Jakarta Sans !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 24px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
  color: #111827 !important;
  border-bottom: 0px !important;
  margin-top: 0px !important;
}
.rs-calendar-header .rs-calendar-header-month-toolbar button {
  font-family: Plus Jakarta Sans !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 24px !important;
  letter-spacing: 0em !important;
  text-align: center !important;
  color: #111827 !important;
}
.rs-btn-icon.rs-btn-xs > .rs-icon {
  font-size: 22px !important;
}
.rs-calendar-table-cell,
.rs-calendar-table-header-cell {
  height: 50px !important;
}
.rs-calendar-table-header-cell .rs-calendar-table-header-cell-content {
  font-family: Plus Jakarta Sans !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 24px !important;
  letter-spacing: 0em !important;
  text-align: center !important;
  color: #30907d !important;
}

.rs-picker-menu
  .rs-calendar
  .rs-calendar-table-cell.rs-calendar-table-cell-in-range
  .rs-calendar-table-cell-content
  .rs-calendar-table-cell-day {
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-weight: 400 !important;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: #111827;
}
.rs-picker-menu
  .rs-calendar
  .rs-calendar-table-cell.rs-calendar-table-cell-un-same-month
  .rs-calendar-table-cell-content
  .rs-calendar-table-cell-day {
  color: #9ca3af !important;
}
.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
  background-color: #00976d !important;
  border-radius: 50%;
  padding: 2px !important;
}
.rs-calendar-table-cell-selected
  .rs-calendar-table-cell-content
  .rs-calendar-table-cell-day {
  font-family: Plus Jakarta Sans;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 24px !important;
  letter-spacing: 0em !important;
  text-align: center !important;
  color: #ffffff !important;
}
.rs-calendar-table-cell-selected::before,
.rs-calendar-table-cell-in-range::before {
  height: 45px !important;
}
.rs-calendar-table-cell-in-range::before {
  background-color: rgb(224 255 225 / 50%) !important;
}
.rs-picker-daterange-menu
  .rs-picker-daterange-panel-show-one-calendar
  .rs-picker-toolbar {
  max-width: 100% !important;
  border-top: 0px !important;
}
.rs-picker-daterange-menu
  .rs-picker-daterange-panel-show-one-calendar
  .rs-picker-toolbar
  .rs-stack-item
  .rs-picker-toolbar-right
  button {
  width: 120px !important;
  height: 40px !important;
  background-color: #00d26e !important;
  font-family: Plus Jakarta Sans;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 24px !important;
  letter-spacing: 0em !important;
  text-align: center !important;
  color: #001912 !important;
  margin: 15px 0px !important;
}
.dashboard-new-sync img {
  width: auto;
}
.dashboard-new-sync {
  display: flex;
  justify-content: center;
}
