.amazon-conection{
  margin-bottom: 3rem;
  margin-top: 6rem !important;
}
.amazon-conection .amazonConectionHeading {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.amazon-conection .amazonConnectionCard {
  border: 1px solid #d2d2d2;
  border-radius: 5px;
}
.amazon-conection .amazonConectionHeading h2 {
  color: #111827 !important;
  font-size: 24px !important;
  font-weight: 600 !important;
  line-height: 28.8px;
}
.amazon-conection .amazonConectionHeading .btn-primary {
  height: 35px !important;
}
.notification-bar {
  background-color: #00B470; /* Green color for success */
  padding: 10px;
  border: 1px solid #85f197; /* Darker green border */
  border-radius: 5px;
  margin-bottom: 15px;
}

.upgradePlan{
  /* color: #B58900 !important; */
  color: rgb(255, 253, 235) !important;
}